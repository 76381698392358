import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { CartState } from '@app/store/state/cart.state';
import { PaymentState } from '@app/store/state/payment.state';
import { environment } from '@env/environment';
import { AlertModule, ButtonLoaderModule, IpsService, NotificationModule } from '@infomaniak/angular-common';
import {
  HashMap,
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_MISSING_HANDLER,
  TRANSLOCO_TRANSPILER,
  TranslocoConfig,
  translocoConfig,
  TranslocoMissingHandler,
  TranslocoModule,
  TranslocoTranspiler,
} from '@ngneat/transloco';
import { HeaderTitleModule } from '@infomaniak/ngx-header-title';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsModule } from '@ngxs/store';

import { AppComponent } from './app.component';
import { routes } from './app.routing';
import { LoaderService } from './core/loader.service';
import { LoginModule } from './core/login/login.module';
import { ProfileResolve } from './core/profile.resolve';
import { ScrollService } from './core/scroll.service';
import { UserService } from './core/store/user.service';
import { IndexComponent } from './index/index.component';
import * as Sentry from '@sentry/angular-ivy';
import { SharedHeaderModule } from '@app/shared/header/header.module';
import { StoreModule } from '@app/store/store.module';
import {DeviceDetectorService} from "ngx-device-detector";

@Injectable()
export class CustomHandler implements TranslocoMissingHandler {
  loggedKeys = [];

  handle(key: string, config: TranslocoConfig, params: HashMap) {
    if (config.missingHandler.logMissingKey && !config.prodMode && !this.loggedKeys.includes(key)) {
      this.loggedKeys.push(key);
      const msg = `Missing translation for '${key}'`;
      console.log(`%c ${msg}`, 'font-size: 12px; color: red');
    }
    const templateMatcher: RegExp = /\$(.*?)\$/gm;
    // console.warn(value, params, translation);
    // if (params['autreMethode']) {
    //   console.warn(value, params, translation);
    // }
    const toto = key.replace(templateMatcher, (_, match) => {
      match = match.trim();
      if (params[match]) {
        return params[match];
      }

      return match;
    });
    let helper = '!';
    if (config.prodMode) {
      helper = '';
    }
    return helper + (toto || key);
  }
}

@Injectable()
export class CustomTranspiler implements TranslocoTranspiler {
  transpile(value: any, params, translation: Translation) {
    const templateMatcher: RegExp = /\$(.*?)\$/gm;
    if (typeof (value) === 'string') {
      // console.warn(value, params, translation);
      // if (params['autreMethode']) {
      //   console.warn(value, params, translation);
      // }
      return value.replace(templateMatcher, (_, match) => {
        match = match.trim();
        if (params[match]) {
          return params[match];
        }

        return translation[match] ? translation[match] : '';
      });
    }
    return value;
  }
}


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([CartState, PaymentState], {
      developmentMode: (environment.env !== 'prod')
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({ disabled: (environment.env === 'prod') }),
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'SHOP-XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    TranslocoModule,
    CoreModule,
    SharedModule.forRoot(),
    LoginModule,
    RouterModule.forRoot(routes, { enableTracing: false, scrollPositionRestoration: 'enabled', paramsInheritanceStrategy: 'always', relativeLinkResolution: 'legacy' }),
    MatButtonModule,
    MatProgressBarModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatDialogModule,
    AlertModule,
    MatCardModule,
    MatSnackBarModule,
    NotificationModule,
    ScrollingModule,
    ButtonLoaderModule,
    HeaderTitleModule,
    SharedHeaderModule,
    StoreModule,
  ],
  declarations: [
    AppComponent,
    IndexComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    UserService,
    // { provide: ErrorHandler, useFactory: errorHandlerFactory },
    ProfileResolve,
    ScrollService,
    LoaderService,
    IpsService,
    DeviceDetectorService,
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        defaultLang: window['CONST_LANG'].shortcode,
        availableLangs: ['en', 'de', 'fr', 'es', 'it'],
        fallbackLang: 'en',
        reRenderOnLangChange: true,
        prodMode: environment.production,
        missingHandler: {
          useFallbackTranslation: true
        }
      })
    },
    {
      provide: TRANSLOCO_TRANSPILER,
      useClass: CustomTranspiler
    },
    {
      provide: TRANSLOCO_MISSING_HANDLER,
      useClass: CustomHandler
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
      useValue: undefined
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
